<template>
  <section class="section-block">
    <SectionTitle title="會員中心連結" btn="更新連結" @edit="createLiff('appointment')" />
    <el-form>
      <el-form-item label="會員中心">
        <div class="copy-block">
          <a :href="profileUrl">
            {{ liffLink('appointment') ? profileUrl : '尚未設定，請聯繫客服' }}
          </a>
          <div>
            <el-button
              :disabled="!profileUrl"
              class="copy-btn"
              type="primary"
              :class="{ disable: !profileUrl }"
              @click="copyLink(profileUrl)"
            >
              複製
            </el-button>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle'
import copy from 'clipboard-copy'
import { CreateLiff } from '@/api/shop'
import { mapGetters } from 'vuex'

export default {
  name: 'MemberCenterLink',
  components: {
    SectionTitle,
  },
  props: ['liff'],
  computed: {
    ...mapGetters(['shop']),
    liffUrl () {
      if (!this.liffLink('appointment')) return false
      return this.liffLink('appointment').liffUrl
    },

    profileUrl () {
      if (!this.liffLink('appointment')) return false
      return `${this.liffLink('appointment').liffUrl}/profile`
    },
  },
  methods: {
    liffLink (name) {
      if (!this.liff) return false
      return this.liff.find((item) => item.name === name)
    },

    copyLink (link) {
      copy(link)
      this.$message.success('已成功複製連結！')
    },
    async createLiff (name) {
      try {
        await CreateLiff({ shopId: this.shop, name })
        await this.$emit('refresh')
        this.$message.success('更新 Liff 連結成功!')
      } catch (error) {
        console.log(error)
        this.$message.error(error)
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.copy-block {
  @apply flex items-center;
}
.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
