<template>
  <BaseDialog
    title="會員卡顯示設定"
    class="member-center-show-edit-modal"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="updateFunctionMenu"
  >
    <el-form ref="formRef" :model="formData" :rules="formRules">
      <el-form-item label="排序一" prop="order1">
        <el-select v-model="formData.order1" placeholder="請選擇" clearable>
          <el-option
            v-for="option in showOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
            :disabled="disabledOption(option.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="排序二" prop="order2">
        <el-select v-model="formData.order2" placeholder="請選擇" clearable>
          <el-option
            v-for="option in showOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
            :disabled="disabledOption(option.value)"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="排序三" prop="order3">
        <el-select v-model="formData.order3" placeholder="請選擇" clearable>
          <el-option
            v-for="option in showOptions"
            :key="option.value"
            :label="option.label"
            :value="option.value"
            :disabled="disabledOption(option.value)"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { UpdateMemberCenterConfig } from '@/api/member/memberCenter'
import { map, filter, get, values, find, indexOf } from 'lodash'
import { usePermissions } from '@/use/permissions'
import store from '@/store'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'MemberCardShowEditModal',
  components: { BaseDialog },
  props: {
    configData: { type: Array, default: () => [] },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const { checkAction } = usePermissions()
    const { initFormData, formData, formRef, loading } = useBaseForm()
    initFormData({
      order1: null,
      order2: null,
      order3: null,
    })
    const usePospal = computed(() => checkAction('adminView.memberCenterConfig.findPosPalMemberWallet'))
    const compactData = computed(() => {
      // const test = [{ type: 'cashbackBalance', order: 1, enable: false }, { type: 'pointBalance', order: null, enable: false }, { type: 'classTicketRecordCount', order: 3, enable: false }, { type: 'test', order: 3, enable: false }]
      const settingTypes = ['walletBalance', 'couponCount', 'cashbackBalance', 'pointBalance', 'classTicketRecordCount', 'posPalMemberBalance']
      const showTypes = values(formData)
      const configData = props.configData
      configData.forEach(config => {
        if (showTypes.includes(config.type)) {
          const order = indexOf(showTypes, config.type)
          config.order = order + 1
          config.enable = true
        } else {
          if (settingTypes.includes(config.type)) {
            config.enable = false
            delete config.order
          }
        }
      })

      for (const key in formData) {
        const type = formData[key]
        const exist = find(configData, { type })
        if (!exist && type) {
          let order = indexOf(showTypes, type)
          if (order === 0) order = 1
          configData.push({
            type,
            enable: true,
            order,
          })
        }
      }

      return configData
    })
    const formRules = computed(() => {
      const rules = {}

      if (formData.order3) {
        rules.order1 = [noEmptyRules()]
        rules.order2 = [noEmptyRules()]
      }

      if (formData.order2) {
        rules.order1 = [noEmptyRules()]
      }

      return rules
    })

    const showOptions = computed(() => {
      const options = [
        { label: '儲值金', value: 'walletBalance' },
        { label: '票券', value: 'couponCount' },
        { label: '回饋金', value: 'cashbackBalance' },
        { label: '點數', value: 'pointBalance' },
        { label: '堂票', value: 'classTicketRecordCount' },
      ]

      if (usePospal.value) {
        options.push({
          label: '會員餘額',
          value: 'posPalMemberBalance',
        })
      }

      return options
    })

    const updateFunctionMenu = async () => {
      loading.value = true
      if (!await formUtils.checkForm(formRef.value)) {
        loading.value = false
        return
      }
      const [, err] = await UpdateMemberCenterConfig({
        shopId: shopId.value,
        functionMenuSetting: compactData.value,
      })
      loading.value = false
      if (err) return window.$message.error(err)
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const existOption = (type) => {
      const exist = Boolean(find(showOptions.value, { value: type }))
      if (!exist) return null
      return type
    }

    const syncData = () => {
      const data = props.configData
      console.log('data', data)
      const order1 = get(find(data, { order: 1 }), 'type')
      const order2 = get(find(data, { order: 2 }), 'type')
      const order3 = get(find(data, { order: 3 }), 'type')

      formData.order1 = existOption(order1)
      formData.order2 = existOption(order2)
      formData.order3 = existOption(order3)
    }

    const disabledOption = (type) => {
      const selected = values(formData)
      const exist = selected.includes(type)
      return exist
    }

    onMounted(() => {
      syncData()
    })

    return { formData, formRef, formRules, showOptions, updateFunctionMenu, find, disabledOption, loading, compactData }
  },
})
</script>

<style lang="postcss" scoped>

</style>
