<template>
  <BaseDialog
    title="外部系統會員卡"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <el-form ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-form-item label="啟用外部系統會員卡" prop="enable">
        <el-switch
          v-model="formData.enable"
          active-text="開啟"
          inactive-text="關閉"
        />
      </el-form-item>
      <el-form-item label="外部系統廠商" prop="externalType">
        <el-select v-model="formData.externalType" placeholder="請選擇">
          <el-option
            label="微碧"
            value="weiby"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="會員條碼類型" prop="type">
        <el-select v-model="formData.type" placeholder="請選擇">
          <el-option
            label="QR Code"
            value="qrcode"
          />
        </el-select>
      </el-form-item>
    </el-form>
  </BaseDialog>
</template>

<script>
import { defineComponent, reactive, ref, computed, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { UpdateMemberCenterQRCode } from '@/api/member/memberCenter'
import store from '@/store'

export default defineComponent({
  name: 'ExternalMemberCenterEditModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const shopId = computed(() => store.getters.shop)
    const loading = ref(false)
    const formRef = ref(null)
    const formData = reactive({
      enable: false,
      isExternal: true,
      type: 'qrcode',
      externalType: 'weiby',
    })
    const formRules = {
      enable: [noEmptyRules()],
      type: [noEmptyRules()],
      externalType: [noEmptyRules()],
    }

    const updateMemberCenterQRCode = async () => {
      const [, err] = await UpdateMemberCenterQRCode({
        shopId: shopId.value,
        ...formData,
      })
      if (err) return window.$message.error(err)
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const onConfirm = async () => {
      loading.value = true
      const formPass = await formUtils.checkForm(formRef.value)
      if (!formPass) {
        loading.value = false
        return
      }
      await updateMemberCenterQRCode()
      loading.value = false
    }

    const syncData = () => {
      const config = props.configData
      formData.enable = config.enable
    }

    onMounted(() => {
      syncData()
    })

    return { formData, loading, formRef, formRules, onConfirm }
  },
})
</script>

<style lang="postcss" scoped>

</style>
